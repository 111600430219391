import "instantsearch.css/themes/algolia-min.css";
import React from "react";
import {
  InstantSearch,
  SearchBox,
  Highlight,
  SortBy,
  InfiniteHits,
} from "react-instantsearch";
import "./App.css";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import { convertSnowflakeToDate } from "./util";

const MEILI_ENDPOINT = process.env.REACT_APP_MEILI_ENDPOINT ||
  "https://search.memestream.strix.systems"

const MEILI_KEY = process.env.REACT_APP_MEILI_KEY ||
  '040586799aee83b633a4935d8c0e045e9fde9e78cff0dfe542ee10583272d40d'

console.log({ MEILI_ENDPOINT, MEILI_KEY })

const { searchClient } = instantMeiliSearch(
  MEILI_ENDPOINT,
  MEILI_KEY,
  {
    finitePagination: false,
    placeholderSearch: true,
  }
);


const placeholders = [
  "we're so back",
  "it's so over",
  'freud',
  'hegel',
  'satire',
  'literally me',
  'rake',
  'we are not the same',
  'anarchy',
  'goblin',
  'wise'
]


// <SortBy items={[
//   { label: 'Latest', value: 'memes:attachmentId:desc'}
// ]} />

const placeholder = placeholders[Math.floor(Math.random() * placeholders.length)]

const initialUiState= {
  memes: {
    query: '',
    sortBy: 'memes:postedAt:desc',
}
}

const App = () => (
  <div className="ais-InstantSearch">
    <h1>MemeStream</h1>
    <InstantSearch indexName="memes" searchClient={searchClient} initialUiState={initialUiState} >
      <SearchBox autoFocus placeholder={placeholder}/>
      <InfiniteHits hitComponent={Hit} />
      <SortBy items={[{ label: 'Latest', value: 'memes:attachmentId:desc'}]} />
    </InstantSearch>
  </div>
);

const Hit = ({ hit }) => (
  <div class="hit" key={hit.id}>
    <a href={hit.url} class="img" target="_blank" rel="noreferrer">
      <img src={hit.url} alt={hit.text} />
    </a>
    <div className="info">
      <div>{hit.tags[0]} #{hit.tags[1]} @{hit.tags[2]}</div>
      <div>{convertSnowflakeToDate(hit.attachmentId).toLocaleString()}</div>
    </div>
    <Highlight className="text" attribute="text" hit={hit} />
  </div>
);

export default App;
